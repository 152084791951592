<template>
  <div id="app">
    <Popups/>
    <Notifications/>
    <router-view :key="$route.fullPath"/>
    <div id="loader" v-if="$store.state.isLoading">
      <LoadingLogo></LoadingLogo>
    </div>
    <image-zoomer v-if="!helper.empty($store.state.imageZoom)"/>
  </div>
</template>

<style>
:root {
  --primary: #3ddad7;
  --secondary: #f8069c;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;

}

#loader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2000;
}

#nav {
  padding: 30px;
}

a {
  /*color: inherit!important;*/
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a i {
  /*color:gray!important*/
}

#nav a.router-link-exact-active {
  color: #42b983;
}


#app {
  flex: 1;
  display: flex;
}

.input-group-append .input-group-text {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.input-group-prepend .input-group-text {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.title {
  font-size: 2em;
  font-weight: bolder;
  color: var(--secondary);
  text-align: left;
  align-items: center;
  padding: 20px 0;
}

.title i {
  color: var(--secondary);
}

i,
i:after,
i:before {
  /*color: inherit !important;*/
}

.signature {
  font-size: .9em;
  font-style: italic;
  font-weight: bolder;
}

button.close {
  border: none;
  background: transparent;
}


</style>
<script>
import {Component, Vue} from 'vue-property-decorator';
import Popups from "./components/Popups.vue";
import Loading from "./components/Loading";
import LoadingLogo from "./components/LoadingLogo";
import {auth} from './services/Auth';

import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {onMessage, getToken, getMessaging} from "firebase/messaging";
import {DeviceUUID} from 'device-uuid';
import {api} from "@/services/Api";
import Notifications from "@/components/Notifications.vue";
import UserNotification from "@/entity/Notification";
import ImageZoomer from "@/components/ImageZoomer.vue";
import {liveManager} from "@/services/LiveManager";

@Component({
  components: {ImageZoomer, Notifications, LoadingLogo, Loading, Popups}
})
export default class App extends Vue {

  publicPath = process.env.BASE_URL

  firebaseConfig = {
    apiKey: "AIzaSyDsEqmOFXyXhzpqoEkXPsfO0KN3HleOTj4",
    authDomain: "wiassur-ci.firebaseapp.com",
    projectId: "wiassur-ci",
    storageBucket: "wiassur-ci.appspot.com",
    messagingSenderId: "574176180579",
    appId: "1:574176180579:web:11629b32695c9cec77fb56",
    measurementId: "G-NXLWWKNNH6"
  };

  async beforeMount() {
    this.checkSource()
    this.checkAffiliation()
    try {
      this.requestPermission()
    } catch (e) {
    }
  }

  async registerDevice() {
    if (Notification.permission === 'granted') {
      const app = initializeApp(this.firebaseConfig);
      const messaging = getMessaging(app)
      getToken(messaging, {vapidKey: "BECYklXswYVf_6ehEOqPOzmNQ85XyKp4uM2EdIQ6NGFaFE9wJNfIKh11FfrFwspFHC8ejuB14MX7bq2aiV0_iQg"})
          .then(async (currentToken) => {
            const localToken = localStorage.getItem('fcm')
            // console.log(currentToken)
            if (currentToken && currentToken !== localToken) {
              // console.log('App client token', currentToken)
              var uuid = new DeviceUUID().get();
              var parsed = new DeviceUUID().parse();
              const data = {
                id: uuid,
                token: currentToken,
                brand: this.$device.brand,
                model: this.$device.model,
                name: parsed.platform,
                type: this.$device.type,
                isEmulator: false,
                isSecured: false,
              };
              if (auth.hasRole('ROLE_MANAGER')) {
                const res = await api.post(api.auth, 'user/register/device', data)
                if (res && res.data) {
                  localStorage.setItem('fcm', currentToken)
                }
              } else if (auth.hasRole('ROLE_PARTNER')) {
                const res = await api.post(api.auth, 'partner/api/register/device', data)
                if (res && res.data) {
                  localStorage.setItem('fcm', currentToken)
                }
              }
              // console.log(data)
            }
          }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      })
      onMessage(messaging, (payload) => {
        if (payload.data.mode === 'DATA') {

        } else if (payload.data.type === 'MESSAGE_RECEIVED') {
          const route = JSON.parse(payload.data.route)
          payload.data.subtitle = 'Whatsapp'
          payload.data.photo = 'fab fa-whatsapp'
          if (this.$route.name !== route.name && payload.data.mode !== 'DATA' && payload.data.mode !== 'SILENT') {
            new UserNotification(payload.data)

            try {
              this.notificationAudio.play()
            } catch (e) {
            }
          }
        } else if (payload.data.mode !== 'SILENT') {
          new UserNotification(payload.data)
          try {
            this.notificationAudio.play()
          } catch (e) {
          }
        }
      });
    }

  }

  notificationAudio = new Audio(this.publicPath + 'notification.wav');

  requestPermission() {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        // onMessage(this.$messaging, (payload) => {
        //   try {
        //     this.notificationAudio.play()
        //   } catch (e) {
        //   }
        //   const data = payload.data
        // });

      }
    })
  }

  notificationIds = []

  swVersion = '2'

  updateWorker() {
    const currentVersion = localStorage.getItem('swVersion')
    if (currentVersion !== this.swVersion) {
      console.log('update sw')
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          // console.log('unregister sw')
          registration.update();
        }

      });
      localStorage.setItem('swVersion', this.swVersion)
    }
  }

  async mounted() {
    this.updateWorker()
    this.detectZone()
    this.registerDevice()

    liveManager.listenEngineUpdate()

    navigator.serviceWorker.addEventListener('message', (event) => {
      console.log(event)
      if (event.data && event.data.action === 'NOTIFICATION') {
        const payload = event.data.payload
        if (payload.type === 'SMS_ERROR') {
          const notificationAudio = new Audio(process.env.BASE_URL + 'notification.wav');
          new UserNotification(payload)
          notificationAudio.play()
        }
      }
      // if (event.data.action) {
      //   console.log(event.data.action.toUpperCase())
      //   switch (event.data.action.toUpperCase()) {
      //     case 'PLAY_NOTIFICATION_SOUND': {
      //       try {
      //         if (event.data.data.mode !== 'SILENT') {
      //           this.notificationAudio.play().catch((e) => {
      //             console.log(e)
      //           })
      //         }
      //       } catch (e) {
      //       }
      //       break;
      //     }
      //     case 'NOTIFICATION': {
      //       if (event.data.data.mode.toUpperCase() !== 'SILENT') {
      //         new UserNotification(event.data.payload)
      //       }
      //       break;
      //     }
      //     case 'REDIRECT': {
      //       if (event.data.url) {
      //         window.location = event.data.url
      //       }
      //       if (event.data.route) {
      //         this.$router.push(JSON.parse(event.data.route))
      //       }
      //     }
      //   }
      // }
    })
  }

  checkSource() {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    if (urlParams.has('source')) {
      const source = urlParams.get('source')
      if (source) {
        this.$store.state.source = source
      }
    }
  }

  checkAffiliation() {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    if (urlParams.has('affiliation')) {
      const affiliation = urlParams.get('affiliation')
      if (affiliation) {
        this.$store.state.affiliation = affiliation
      }
    }
  }

  detectZone() {

  }

  deliveryTimeout = null

  loadDeliveryQueries() {
    if (auth.hasRole('ROLE_MANAGER')) {
      this.deliveryTimeout = setTimeout(() => {
        this.$store.getters.deliveries()
        this.loadDeliveryQueries()
      }, 60000)
    }
  }
}
</script>
