<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    {{ trans('Survey_list') }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">
            <b-dropdown no-caret no-flip right size="sm" tag="div" toggle-class="topbar-item text-decoration-none"
                        variant="link" v-if="hasRole('ROLE_MANAGER')">
              <template v-slot:button-content>
                <div class="btn btn-sm btn-clean btn-dropdown btn-primary">
                  <i class="fad fa-file-signature fs-2"></i>
                  {{ trans('Créer un contrat') }}
                </div>
              </template>
              <b-dropdown-text class="min-w-md-350px p-0" tag="div">
                <Loading v-if="isLoading"></Loading>
                <b-tabs active-nav-item-class="bg-primary text-white" active-tab-class="" fill nav-wrapper-class=""
                        v-else>
                  <b-tab :title="cat.label" :title-link-class="'py-4'" :key="cat.id" v-for="cat in branchCategories">
                    <div class="row g-0 overflow-auto mh-325px">
                      <div class="col-6" v-for="branch in cat.branches">
                        <router-link :style="{ '--bg-hover': branch.color }"
                                     :to="{ name: 'contract-creator', params: { branchSlug: branch.slug } }"
                                     class="d-flex flex-column flex-center h-100 p-6  border-end border-bottom branch-button">
                        <span class="svg-icon svg-icon-3x svg-icon-success mb-2">
                          <i :class="branch.icon + ' fs-3x'" v-if="branch.icon"></i>
                          <i class="fad fa-question-circle" v-else></i>
                        </span>
                          <span class="fs-5 fw-bold text-gray-400 mb-0">
                          {{ branch.label }}
                        </span>
                        </router-link>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </b-dropdown-text>
            </b-dropdown>
          </div>

        </div>
      </div>
    </template>
    <template v-slot:content>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-auto flex-grow-1">
              <label class="mb-2 fw-bolder" for="">{{ trans('Branche') }}</label>
              <b-form-select class="form-control  form-select" v-model="searched.branch" @change="updateBranch">
                <b-form-select-option :value="null">{{ trans('Choisir une branche') }}
                </b-form-select-option>
                <template v-for="category in branchCategories">
                  <b-form-select-option :key="branch.uuid" :value="branch.uuid"
                                        v-for="branch, key in category.branches">
                    {{ category.label }} - {{ trans(branch.label) }}
                  </b-form-select-option>
                </template>
              </b-form-select>
            </div>

            <div class="col-auto flex-grow-1">
              <label class="mb-2 fw-bolder" for="">{{ trans('Status') }}</label>
              <b-form-select class="form-control  form-select" v-model="searched.status">
                <b-form-select-option :value="null">{{ trans('Choisir un status') }}
                </b-form-select-option>
                <template v-for="(status, index) in CONSTANTS.CONTRACT_STATUS">
                  <b-form-select-option :key="'status_' + index" :value="status">
                    {{ status }}
                  </b-form-select-option>
                </template>
              </b-form-select>
            </div>

            <div class="col-auto flex-grow-1">
              <label class="fw-bolder mb-2" for="">
                Assureur
              </label>
              <div>
                <b-form-select class="form-control  form-select" v-model="searched.insurer">
                  <b-form-select-option :value="null">{{ trans('Choisir un assureur') }}
                  </b-form-select-option>
                  <b-form-select-option :key="insurer.uuid" :value="insurer.slug" v-for="insurer in insurers">
                    {{ insurer.label }}
                  </b-form-select-option>
                </b-form-select>
              </div>
            </div>
            <div class="col-auto flex-grow-1">
              <label class="fw-bolder mb-2" for="">
                Souscripteur
              </label>
              <div>
                <input class="form-control" placeholder="Email,Nom,Prenom,Numero de piece" type="text"
                       @keyup.enter="search" v-model="searched.customer">
              </div>
            </div>
          </div>


          <div class="row my-10">
            <div class="col-auto flex-grow-1">
              <label class="fw-bolder mb-2" for="">
                Uuid / Id
              </label>
              <div>
                <input class="form-control" placeholder="Identifiant" type="text" @keyup.enter="search"
                       v-model="searched.uuid">
              </div>
            </div>
            <div class="col-auto flex-grow-1">
              <label class="fw-bolder mb-2" for="">
                Numero de police
              </label>
              <div>
                <input class="form-control" placeholder="Partiel ou complet" type="text" @keyup.enter="search"
                       v-model="searched.police">
              </div>
            </div>
            <div class="col-auto flex-grow-1" v-if="hasRole('ROLE_MANAGER')">
              <label class="fw-bolder mb-2" for="">
                Apporteur
              </label>
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <input class="form-control" placeholder="Apporteur" type="text" v-model="searched.partner"
                         @keyup.enter="search">
                </div>
              </div>
            </div>
            <div class="col-auto flex-grow-1" v-if="hasRole('ROLE_MANAGER')">
              <label class="fw-bolder mb-2" for="">
                Gestionnaire
              </label>
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <input class="form-control" placeholder="Gestionnaire" type="text" v-model="searched.manager"
                         @keyup.enter="search">
                </div>
              </div>
            </div>
          </div>

          <div class="row my-10">
            <div class="col-auto flex-grow-1">
              <label class="fw-bolder mb-2" for="">
                Date d'effet entre :
              </label>
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <input class="form-control" type="date" v-model="searched.effetMin">
                </div>
                <div class="mx-3 fw-bolder">
                  et
                </div>
                <div class="flex-grow-1">
                  <input class="form-control" type="date" v-model="searched.effetMax">
                </div>
              </div>
            </div>

            <div class="col-auto flex-grow-1 border-start border-2 border-start-dashed">
              <label class="fw-bolder mb-2" for="">
                Expire entre :
              </label>
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <input class="form-control" type="date" v-model="searched.expireMin">
                </div>
                <div class="mx-3 fw-bolder">
                  et
                </div>
                <div class="flex-grow-1">
                  <input class="form-control" type="date" v-model="searched.expireMax">
                </div>
              </div>
            </div>

            <div class="col-auto flex-grow-1 border-start border-2 border-start-dashed">
              <label class="fw-bolder mb-2" for="">
                Créer entre :
              </label>
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <input class="form-control" type="date" v-model="searched.createMin">
                </div>
                <div class="mx-3 fw-bolder">
                  et
                </div>
                <div class="flex-grow-1">
                  <input class="form-control" type="date" v-model="searched.createMax">
                </div>
              </div>
            </div>
          </div>
          <!--          <div class="d-flex justify-content-center">-->
          <!--            <div @click="resetSearch" class="btn btn-danger me-5 d-flex align-items-center">-->
          <!--              <i class="fad fa-trash"></i>-->
          <!--              Réinitialiser-->
          <!--            </div>-->
          <!--            <button :disabled="searching" @click="search" class="btn btn-primary d-flex align-items-center">-->
          <!--              <i class="fad fa-search"></i>-->
          <!--              Rechercher-->
          <!--            </button>-->
          <!--          </div>-->


          <!--          <div class="fs-2 fw-bolder mb-2 cursor-pointer text-hover-primary" @click="advancedSearch = !advancedSearch">-->
          <!--            <i class="fad fa-caret-right me-2 fs-2" v-if="!advancedSearch"></i>-->
          <!--            <i class="fad fa-caret-down me-2 fs-2" v-else></i>-->
          <!--            Recherche avancée-->
          <!--          </div>-->
          <div>
            <div class="row my-10">
              <div class="col-auto flex-grow-1">
                <label class="mb-2 fw-bolder" for="">{{ trans('Objet') }}</label>
                <b-form-select class="form-control  form-select" v-model="searched.itemType">
                  <template v-if="searched.branch">
                    <b-form-select-option :value="null">{{ trans('Choisir un objet') }}
                    </b-form-select-option>
                    <template v-for="itemType in itemTypes">
                      <template v-if="itemType.branch.uuid === searched.branch">
                        <b-form-select-option :key="itemType.uuid" :value="itemType.uuid">
                          {{ itemType.name }}
                        </b-form-select-option>
                      </template>
                    </template>
                  </template>
                  <b-form-select-option :value="null" v-else>{{ trans('Choisir une branche') }}</b-form-select-option>
                </b-form-select>
              </div>

              <div class="col-auto flex-grow-1">
                <label class="mb-2 fw-bolder" for="">{{ trans('Propriété') }}</label>
                <b-form-select class="form-control  form-select" v-model="searched.itemProperty">
                  <template v-if="searched.itemType">
                    <b-form-select-option :value="null">{{ trans('Choisir une propriété') }}</b-form-select-option>
                    <template v-for="itemType in itemTypes">
                      <template v-if="itemType.uuid === searched.itemType">
                        <b-form-select-option :key="property.uuid" :value="property.uuid"
                                              v-for="property in itemType.properties">
                          {{ property.name }}
                        </b-form-select-option>
                      </template>
                    </template>
                  </template>
                  <b-form-select-option :value="null" v-else>{{ trans('Choisir un objet') }}
                  </b-form-select-option>

                </b-form-select>
              </div>

              <div class="col-auto flex-grow-1">
                <label class="mb-2 fw-bolder" for="">{{ trans('Valeur') }}</label>
                <input type="text" class="form-control" v-model="searched.propertyValue" @keyup.enter="search">
              </div>
            </div>

            <div class="d-flex justify-content-center">
              <div @click="resetSearch" class="btn btn-danger me-5 d-flex align-items-center">
                <i class="fad fa-trash"></i>
                Réinitialiser
              </div>
              <button :disabled="searching" @click="search" class="btn btn-primary d-flex align-items-center">
                <i class="fad fa-search"></i>
                Rechercher
              </button>
            </div>
          </div>
        </div>
      </div>


      <div class="fs-2x fw-bolder mt-10 d-flex justify-content-between align-items-center">
        <div>
          {{ matchCount }}
          <template v-if="matchCount > 1">Correspondances</template>
          <template v-else>Correspondance</template>
        </div>

        <div v-if="selected.length > 0" class="d-flex align-items-center">
          {{ selected.length }}
          <template v-if="selected.length <= 1">selectionné</template>
          <template v-else>selectionnés</template>
          <div class="btn btn-danger btn-icon ms-5" @click="deleteContracts(null)">
            <i class="fad fa-trash"></i>
          </div>
        </div>
      </div>
      <div class="card mt-10">
        <div class="card-body">
          <table class="table table-striped gy-3 gs-3">
            <thead>
            <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
              <th>
                <label class="form-check form-check-sm form-check-custom form-check-solid" @click="toggleSelectAll">
                  <input class="form-check-input" data-kt-check="true" data-kt-check-target=".widget-9-check"
                         type="checkbox" :checked="selected.length > 0" value="1"/>
                </label>
              </th>
              <th>{{ trans('Police') }}</th>
              <th>Source</th>
              <th>Souscripteur</th>
              <th>Emission</th>
              <th>Durée</th>
              <th class="text-center">{{ trans('Status/Gestionnaire') }}</th>
              <th v-if="hasRole('ROLE_MANAGER')">{{ trans('Apporteur') }}</th>
              <th>{{ trans('Prime TTC') }}</th>
              <th>{{ trans('Actions') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="searching">
              <td colspan="10">
                <Loading></Loading>
              </td>
            </tr>
            <tr v-else-if="contracts.length === 0">
              <td colspan="10">
                <div class="d-flex flex-column flex-grow-1 align-items-center justify-content-center p-20">
                  <i class="fad fa-empty-set fs-5x"></i>
                  <span class="fs-2 text-gray-400">{{ trans('Aucun resultat') }}</span>
                </div>
              </td>
            </tr>
            <template v-else v-for="contract in contracts">
              <tr v-if="!searched.branch || searched.branch === contract.branch.uuid">
                <td style="vertical-align: middle">
                  <RowSelector v-model="selected" :data="contract.uuid"/>
                </td>
                <td class="position-relative">
                  <i class="fad fa-exclamation-triangle text-danger position-absolute" style="left:-10px"></i>
                  <div>
                    <div class="text-primary fw-bolder d-flex align-items-center fs-7 text-uppercase">
                      <i :class="'fad fs-2 ' + contract.branch.icon" class="me-2 text-inherit"></i>
                      {{ contract.branch.label }} ▪ {{ contract.branch.category.label }}
                    </div>
                    <div class="d-flex align-items-center fw-bolder">
                      {{ contract.insurer.label }} ❖ {{ contract.police }}
                    </div>

                    <div class="me-2">
                      {{ readableDate(contract.createdAt, 'dMY H:i') }}
                    </div>
                  </div>
                </td>
                <td>
                  <!--                                    {{ contract.source }}-->
                  <user-view v-model="contract.source"></user-view>
                </td>
                <td>
                  {{ contract.subscriber.name }} {{ contract.subscriber.prename }}
                  <br>
                  <span class="text-gray-400 fw-bolder">
                    {{ contract.subscriber.email }}
                  </span>
                </td>
                <td>
                  <template v-if="contract.dateEmission">
                    {{ readableDate(contract.dateEmission, 'dMY H:i') }}
                    <br>
                    <span class="fw-bolder">
                      <user-view v-model="contract.emitBy"></user-view>
                    </span>
                  </template>
                  <div v-else>
                    <span>
                      <i class="fad fa-empty-set fs-2"></i>
                    </span>
                  </div>
                  <!-- <user-view v-model="contract.emitBy"/> -->

                </td>
                <td>
                  <div class="fw-bolder align-items-center">
                    <div :title="readableDate(contract.startAt, 'dmY')" v-b-tooltip.hover v-if="contract.startAt">
                      {{ readableDate(contract.startAt, 'dMY') }}
                    </div>
                    <div :title="readableDate(contract.expireAt, 'dmY')" v-b-tooltip.hover v-if="contract.expireAt">
                      {{ readableDate(contract.expireAt, 'dMY') }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="d-flex flex-column justify-content-center align-items-center ">
                    <div>
                      <span :class="{
                  'badge-primary': contract.status === CONSTANTS.CONTRACT_STATUS.PAUSED || contract.status === CONSTANTS.CONTRACT_STATUS.WAITING,
                  'badge-warning': contract.status === CONSTANTS.CONTRACT_STATUS.QUOTATION,
                  'badge-success': contract.status === CONSTANTS.CONTRACT_STATUS.ONGOING,
                  'badge-info': contract.status === CONSTANTS.CONTRACT_STATUS.EXPIRED,
                  'badge-danger': contract.status === CONSTANTS.CONTRACT_STATUS.CANCELLED,
                  'badge-dark': contract.status === CONSTANTS.CONTRACT_STATUS.RENEW,
                }" class="badge">
                        {{ contract.status }}
                      </span>
                    </div>
                    <div class="fw-bolder mt-1">
                      <user-view v-model="contract.manager"></user-view>
                    </div>

                  </div>
                </td>
                <td>
                  <template v-if="contract.partner && hasRole('ROLE_MANAGER')">
                    <div class="fw-bolder">
                      {{ contract.partner.name }}
                    </div>
                    <div class="text-gray-400 fw-bolder fs-8">
                      {{ contract.partner.code }}
                    </div>
                  </template>
                </td>
                <td>
                  <span class="fw-bolder">
                    {{ currency(contract.primeTTC) }}
                  </span>
                  <br>
                  <span class="text-danger fw-bolder">
                    {{ currency(contract.balance, true) }}
                  </span>
                </td>
                <td class="text-end">
                  <router-link :to="{ name: 'contract-show', params: { contractUuid: contract.uuid } }"
                               class="btn btn-xs btn-icon btn-primary">
                    <i class="fad fa-eye"></i>
                  </router-link>

                  <div class="btn btn-xs btn-icon btn-danger ms-3" @click="deleteContracts(contract.uuid)"
                       v-if="hasRole('ROLE_ADMIN')">
                    <i class="fad fa-trash"></i>
                  </div>
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
      </div>

      <div class="my-20">
        <Pagination :count="total" :itemLength="contracts.length" :matchCount="matchCount" :perPage="searched.perPage"
                    :showEmpty="true" @input="search" v-model="searched.page"></Pagination>
      </div>
    </template>
  </Base>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import BranchCategory from "@/entity/BranchCategory";
import Branch from "@/entity/Branch";
import Loading from "@/components/Loading.vue";
import {api} from "@/services/Api";
import Pagination from "@/components/Pagination.vue";
import UserView from "@/components/UserView.vue";
import Contract from "@/entity/Contract";
import Swal from "sweetalert2";
import User from "@/entity/User";
import Partner from "@/entity/Partner";
import PaymentMethod from "@/entity/PaymentMethod";
import Popup from "@/entity/Popup";
import Insurer from "@/entity/Insurer";
import CONSTANTS from "@/Utils/constant";
import RowSelector from "@/components/RowSelector.vue";
import {helper} from "@/services/Helper";
import DateRangePicker from "vue2-daterange-picker";

@Component({
  methods: {},
  components: {RowSelector, Pagination, Loading, Base, UserView, DateRangePicker}
})
export default class ContractList extends Vue {
  total = 0
  selected: any[] = []
  matchCount = 0
  uploadRoot = api.uploadRoot
  branchCategories: BranchCategory[] = []
  itemTypes: any[] = []
  searching = false
  advancedSearch = false
  searched: any = {
    branch: null,
    customer: null,
    insurer: null,
    partner: null,
    manager: null,
    uuid: null,
    police: null,
    status: null,
    effetMin: null,
    effetMax: null,
    expireMin: null,
    expireMax: null,
    createMin: null,
    createMax: null,
    itemType: null,
    itemProperty: null,
    propertyValue: null,
    page: 1,
    perPage: 50
  }
  contracts: any[] = []

  toggleSelectAll() {
    if (helper.empty(this.selected)) {
      this.contracts.forEach((c: Contract) => {
        if (!this.selected.includes(c.uuid)) {
          this.selected.push(c.uuid)
        }
      })
    } else {
      this.selected = []
    }
  }

  updateBranch() {
    this.searched.itemType = null
    this.searched.itemProperty = null
  }

  resetSearch() {
    const keys = Object.keys(this.searched)
    keys.forEach((k: any) => {
      this.searched[k] = null
    })
    this.searched.page = 1
    this.searched.perPage = 50
  }

  async deleteContracts(uuid: any = null) {
    if (!helper.empty(uuid)) {
      this.selected = [uuid]
    }
    if (this.selected.length === 0) {
      return
    }
    const response = await Swal.fire({
      title: 'Supprimer',
      html: 'Êtes-vous sûr de vouloir supprimer ces ' + this.selected.length + ' contrats ?',
      timer: 10000,
      timerProgressBar: true,
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      didOpen: () => {
        // Swal.showLoading()
        // const b = Swal.getHtmlContainer().querySelector('b')
        // timerInterval = setInterval(() => {
        //     b.textContent = Swal.getTimerLeft()
        // }, 100)
      },
      willClose: () => {
        // clearInterval(timerInterval)
      }
    })
    if (response.isConfirmed) {
      this.$store.commit('loading')
      const res = await api.post(api.core, 'contract/delete', {uuids: this.selected})
      if (res) {
        this.selected = []
        this.search()
      }
      this.$store.commit('stopLoading')

    }
  }

  async search() {
    if (this.searching) {
      return
    }
    this.searching = true
    const res = await api.post(api.core, 'contract/search', {searched: this.searched})
    if (res && res.data && res.data.contracts) {
      this.contracts = [];
      res.data.contracts.forEach((c: Contract) => {
        const occ = new Contract(c)
        this.contracts.push(occ);
      })
      this.total = res.data.total
      this.matchCount = res.data.matchCount
    }
    this.searching = false
  }

  async mounted() {
    this.$store.commit('loading')
    await this.loadData()
    this.$store.commit('stopLoading')
  }

  async loadData() {
    const res = await api.get(api.core, 'contract/list/requirements')
    if (res && res.data) {
      this.partners = []
      res.data.partners.forEach((p: Partner) => {
        this.partners.push(new Partner(p))
      })
      this.insurers = []
      res.data.insurers.forEach((i: Insurer) => {
        this.insurers.push(new Insurer(i))
      })
      this.branchCategories = []
      res.data.categories.forEach((i: Insurer) => {
        this.branchCategories.push(new BranchCategory(i))
      })
      this.itemTypes = res.data.itemTypes
    }
  }

  isLoading = false

  async loadBranches() {
    this.isLoading = true
    this.branchCategories = await this.$store.getters.branchCategories()
    this.isLoading = false
  }

  partners: Partner[] = []

  async loadPartners() {
    const res = await api.get(api.core, 'partner/list')
    if (res && res.data) {
      this.partners = []
      res.data.partners.forEach((p: Partner) => {
        this.partners.push(new Partner(p))
      })
    }
  }

  managers: User[] = []

  async loadManagers() {
    this.managers = await api.loadManagers()
  }

  insurers: Insurer[] = []

  async loadInsurers() {
    const popup = new Popup('Chargement des assureurs', '', 'warning', 'fad fa-cogs', true)
    const res = await api.get(api.core, 'insurer/list')
    if (res && res.data && res.data.insurers) {
      this.insurers = []
      res.data.insurers.forEach((i: Insurer) => {
        this.insurers.push(new Insurer(i))
      })
    }
    popup.hide()
  }
}
</script>
<style scoped>
.p-0 .b-dropdown-text {
  padding: 0;
}
</style>
