<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <a class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                  </a>
                </li>
              </ul>
            </slot>
          </div>

          <div class="d-flex align-items-center py-1">
          </div>

        </div>
      </div>
    </template>
    <template v-slot:content>

      <div class="d-flex flex-wrap ">
        <div v-for="level in levels" :key="'level_'+level.code" class="px-10"
             style="border-right: 1px gainsboro solid;">
          <div class="card my-5 min-w-500px" v-for="item in level.items" :key="item.uuid">
            <template v-if="item.uuid">
              <transition-group appear class="flex-grow-1 question mw-100" name="slide-up" tag="div">
                <div class="card-body" :key="'body_'+item.uuid">
                  <h2 v-if="item.parent" class="d-flex align-items-center cursor-pointer text-hover-primary"
                      @click="loadItem(item.parentUuid, (item.level-1))">
                    <i class="fad fa-angle-left fs-3x"></i>
                  </h2>
                  <h2>{{ item.model.name }} ▪️ {{ item.alias }}</h2>
                  <h4 class="text-gray-400 fs-5">
                    Créer le {{ readable(item.createdAt) }}
                  </h4>
                  <h4 class="text-gray-400 fs-5">
                    Par {{ item.createBy }}
                  </h4>
                  <table class="table table-striped g-3">
                    <thead>
                    <tr>
                      <th>Propriété</th>
                      <th>Valeur</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="prop in item.properties">
                      <tr v-if="prop.isVisibleIn(items)" :key="prop.uuid">
                        <td class="fw-bolder" valign="middle">{{ prop.name }}</td>
                        <td class="text-end fw-bolder fs-2">

                          <template v-if="prop.model.type.code !== 'OBJECT'">
                            <template v-if="prop.model.type.code === 'TEXT'">
                              <div class="d-flex align-items-center justify-content-end">
                                <div>
                                  <input type="text" class=" form-control form-control-lg" v-model="prop.newValue">
                                </div>
                                <div class="ms-2">
                                  <button class="btn btn-icon btn-success btn-sm"
                                          :disabled="prop.value === prop.newValue" @click="updateProperty(prop)">
                                    <i class="fad fa-check"></i>
                                  </button>
                                </div>
                              </div>
                            </template>
                            <template v-else>
                              {{ prop.formattedValue }}
                            </template>
                          </template>
                          <template v-else-if="prop.children.length > 0">
                            <button class="btn btn-sm btn-primary"
                                    @click="loadItem(prop.children[0].uuid, (item.level+1))">
                              Charger {{ prop.model.name }}
                            </button>
                          </template>

                        </td>
                      </tr>
                    </template>
                    </tbody>
                  </table>
                </div>
              </transition-group>
            </template>
          </div>
        </div>
      </div>
    </template>
  </Base>
</template>
<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import Item from "@/entity/Item";
import {api} from "@/services/Api";
import Loading from "@/components/Loading.vue";
import Property from "@/entity/Property";
import Swal from "sweetalert2";
import {auth} from "@/services/Auth";
import Popup from "@/entity/Popup";

@Component({
  components: {Loading, Base}
})
export default class ItemViewer extends Vue {
  item: Item | null = null
  items: Item[] = []

  levels: any[] = []


  mounted() {
    this.loadItem(this.$route.params.uuid, 0)
  }

  getConvertedValue() {

  }

  async updateProperty(property: Property) {
    if (!auth.isAdmin()) {
      const pop = new Popup('Accès non autorisé', "Vous ne disposez pas des accès necessaire pour effectuer cette opération");
      return
    }
    const response = await Swal.fire({
      title: 'Mise à jour',
      html: 'êtes-vous sûr de vouloir mettre à jour la propriété ' + property.name + ' => ' + property.newValue,
      timer: 10000,
      timerProgressBar: true,
      showCancelButton: true,
      confirmButtonText: 'Mettre à jour',
      didOpen: () => {
        // Swal.showLoading()
        // const b = Swal.getHtmlContainer().querySelector('b')
        // timerInterval = setInterval(() => {
        //     b.textContent = Swal.getTimerLeft()
        // }, 100)
      },
      willClose: () => {
        // clearInterval(timerInterval)
      }
    })
    if (response.isConfirmed) {
      this.$store.commit('loading')
      const res = await api.post(api.core, 'property/update', {id: property.id, value: property.newValue})
      this.$store.commit('stopLoading')
      if (res && res.data) {
        property.value = property.newValue as any
      }


    }
  }

  async loadItem(uuid: string, level: number) {

    this.$store.commit('loading')
    const res = await api.get(api.core, 'item/get/data/' + uuid)
    if (res && res.data && res.data.item) {

      let currentLevel = this.levels.find((l: any) => {
        return l.code === level
      })
      if (!currentLevel) {
        currentLevel = {code: level, items: []}
        this.levels.push(currentLevel)

      }
      currentLevel.items = currentLevel.items.filter((item: Item) => {
        return item.uuid !== res.data.item.uuid
      })
      const occ = new Item(res.data.item)
      occ.level = level
      this.items.push(occ)

      currentLevel.items.push(occ)

      this.levels.sort((a, b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0))

    }
    this.$store.commit('stopLoading')
  }
}
</script>
<style scoped>
</style>
